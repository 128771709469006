<template>
  <!-- 地图容器，包含地图、提示信息及操作按钮 -->
  <div id="tencentMapBox">
    <!-- 地图实际渲染的DOM元素 -->
    <div id="container" />
    
    <!-- 提示框，显示点击的坐标信息 -->
    <div id="info">
      当前点击坐标为：
      <!-- 动态显示点击坐标 -->
      <span v-if="isClick" id="position">{{ clickLocaltion.lat + "," + clickLocaltion.lng }}</span>
      <!-- 当点击事件解除时的提示 -->
      <span v-else>事件解除了</span>
    </div>
    <div id="deviceInfo">设备数据状态</div>
    <div id="clickPopWindow" v-show="showPop">
      点击设备弹窗
      <div >{{ clickLocaltion.lat + "," + clickLocaltion.lng }}</div>
      <el-button type="primary" >打开设备信息</el-button>
    </div>
    <!-- 操作按钮区域 -->
    <div id="button-box">
      <!-- 定位到当前位置按钮 -->
      <el-button type="primary" @click="getLocation">定位到当前位置</el-button>
      <!-- 回到初始化位置按钮 -->
      <el-button type="primary" @click="getInitCenterLocation">定位到初始化位置</el-button>
      <!-- 获取地图中心点坐标按钮 -->
      <el-button type="primary" @click="getCenterLoaction">定位中心点</el-button>
      <!-- 切换地图点击事件绑定状态按钮 -->
      <el-button type="primary" @click="offClick">解除/绑定鼠标点击事件</el-button>
      <!-- 控制提示信息显示/隐藏按钮 -->
      <el-button type="primary" @click="hiddenTest">隐藏/显示文字</el-button>
    </div>
  </div>
</template>


<script>
/* eslint-disable no-undef */
export default {
  name: 'TencentMapTest',
  
  // 数据属性定义
  data() {
    return {
      // 地图实例
      map: null,
      // DOM元素引用，用于存放地图
      temp: null,
      // 地图初始化中心点坐标
      initCenter: {
        lng: 113.38615609056615,
        lat: 23.149150322814897
      },
      // 记录点击的坐标信息
      clickLocaltion: {
        lat: null,
        lng: null
      },
      // 点击事件状态标志
      isClick: true,
      // 是否显示提示信息的标志
      showTest: true,
      showPop: false,
      // 地区
      district: null,
    }
  },
  
  // Vue生命周期钩子，DOM挂载后执行
  created() {
    // 确保DOM已渲染完毕再初始化地图
    this.$nextTick(() => {
      this.initMap();
      this.createMarkerCluster();
      this.initInfoWindow();
      this.highlight();
    })
  },
  
  // 方法集合
  methods: {
    // 地图初始化方法
    initMap() {
      // 解构出初始化中心点的经纬度
      const { lat, lng } = this.initCenter
      // 创建经纬度对象
      const e = new this.$Map.LatLng(lat, lng)
      // 获取地图容器DOM元素
      this.temp = document.getElementById('container')
      // 实例化地图
      this.map = new TMap.Map(this.temp, {
        // 设置地图中心点
        center: e,
        // 设置地图缩放级别
        zoom: 13,
        // 设置俯仰角
        pitch: 43.5,
        // 设置地图旋转角度
        rotation: 45
      })
      // 绑定地图点击事件
      this.mapOnClick()
    },
    // 创建标记集群
    createMarkerCluster(){

    // 点数据数组
      const points = [
        // 员村点
        { position: new TMap.LatLng(23.119574, 113.360786) },
        { position: new TMap.LatLng(23.119798, 113.361684) },
        { position: new TMap.LatLng(23.121133, 113.361827) },
        { position: new TMap.LatLng(23.117303, 113.359741) },
        { position: new TMap.LatLng(23.116816, 113.362365) },
        { position: new TMap.LatLng(23.118338, 113.361705) },
        { position: new TMap.LatLng(23.117729, 113.360595) },
        { position: new TMap.LatLng(23.117885, 113.359802) },
        { position: new TMap.LatLng(23.116935, 113.360820) },
        { position: new TMap.LatLng(23.115674, 113.361903) },
        { position: new TMap.LatLng(23.119508, 113.362648) },
        { position: new TMap.LatLng(23.120681, 113.363447) },
        { position: new TMap.LatLng(23.120080, 113.363915) },

        // 华师点
        { position: new TMap.LatLng(23.136601, 113.353602) },
        { position: new TMap.LatLng(23.137481, 113.354002) },
        { position: new TMap.LatLng(23.136613, 113.354815) },
        { position: new TMap.LatLng(23.136368, 113.353611) },

        // 潭村点
        { position: new TMap.LatLng(23.115400, 113.344829) },
        { position: new TMap.LatLng(23.113559, 113.342844) },
        { position: new TMap.LatLng(23.113311, 113.344624) },
        { position: new TMap.LatLng(23.114446, 113.345730) },
    
        // 珠江新城点
        { position: new TMap.LatLng(23.119116, 113.320264) },
        { position: new TMap.LatLng(23.118349, 113.321794) },
        { position: new TMap.LatLng(23.117895, 113.320536) },
        { position: new TMap.LatLng(23.118792, 113.318782) },
        { position: new TMap.LatLng(23.118067, 113.319610) },
        { position: new TMap.LatLng(23.120229, 113.320436) },

        // 羊城花园
        { position: new TMap.LatLng(23.118023, 113.410507) },
        { position: new TMap.LatLng(23.117361, 113.409890) },
        { position: new TMap.LatLng(23.117687, 113.411545) },
        { position: new TMap.LatLng(23.116298, 113.410123) },
        { position: new TMap.LatLng(23.116662, 113.410927) },
        { position: new TMap.LatLng(23.118152, 113.412486) },
        
        // 柯木塱
        { position: new TMap.LatLng(23.189821, 113.412742) },
        { position: new TMap.LatLng(23.186637, 113.409164) },
        { position: new TMap.LatLng(23.189514, 113.409870) },
        { position: new TMap.LatLng(23.193428, 113.413006) },
        { position: new TMap.LatLng(23.190617, 113.416176) },
        { position: new TMap.LatLng(23.190912, 113.410398) },

      ];

      // 创建点聚合实例
      this.markerCluster = new TMap.MarkerCluster({
        // 设置聚类的唯一标识
        id: 'cluster',
        // 关联到已创建的地图实例
        map: this.map,
        // 启用聚合点的默认样式
        enableDefaultStyle: true,
        // 形成一个聚合点所需的最少标记数量
        minimumClusterSize: 2,
        // 提供包含所有点位置信息的数组
        geometries: points,
        // 点击聚合点时是否自动放大到能区分单个标记的层级
        zoomOnClick: true,
        // 聚合算法中相邻点之间的最大像素距离
        gridSize: 60,
        // 聚合点的中心是否为所有聚合内标记中心的平均位置，默认关闭会使用第一个点的位置
        averageCenter: false,
        // 不再进行聚合的最小缩放级别
        maxZoom: 20,
      });
      // 监听聚合点的点击事件
      this.markerCluster.on('click', this.handleClusterClick);
    },
    highlight() {
      
      this.district = new TMap.service.District({
        polygon: 2, // 返回行政区划边界的类型
      });

      this.district.search({ keyword: 440106 }).then((result) => {
        if (!result || !result.result) return;

        const paths = result.result[0][0].polygon;

        this.map.enableAreaHighlight({
          paths: paths,
          highlightColor: 'rgba(0,0,0,0)',
          shadeColor: 'rgba(0,0,0,0)',
        });

        new TMap.MultiPolyline({
          map: this.map,
          styles: {
            polyline: new TMap.PolylineStyle({
              color: '#017cf7',
              width: 8,
              lineCap: 'round',
              enableBloom: true,
            }),
          },
          geometries: [
            {
              styleId: 'polyline',
              paths: paths,
            },
          ],
        });
      });
    },
    // 聚合点点击事件处理器
    handleClusterClick(event) {
      
      const cluster = event.cluster;
      
      console.log('点击了聚合点，包含标记点数量:',cluster);
      // 这里可以根据需要处理点击聚合点后的逻辑，例如展开显示聚合内的所有点
      // 展示弹窗
      this.showPop = true;
    },
    // 切换地图点击事件监听状态
    offClick() {
      if (this.isClick) {
        // 如果当前是绑定状态，则解除事件监听
        this.map.off('click', this.click)
      } else {
        // 否则重新绑定事件
        this.mapOnClick()
      }
      // 反转点击事件状态
      this.isClick = !this.isClick
    },
    
    // 绑定地图点击事件
    mapOnClick() {
      this.map.on('click', this.click)
    },
    // 初始化信息窗口
    initInfoWindow() {
      const infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: new TMap.LatLng(23.119574, 113.360786),
        offset: { x: 0, y: -32 },
      });

      infoWindow.close(); // 初始关闭信息窗

      this.infoWindow = infoWindow;
    },
    // 处理地图点击事件
    click(evt) {     
       this.showPop = false; 
      // 更新点击位置信息
      this.clickLocaltion.lat = evt.latLng.getLat().toFixed(6)
      this.clickLocaltion.lng = evt.latLng.getLng().toFixed(6)
      console.log(evt);
      // console.log( new TMap.LatLng(23.119574, 113.360786));
      console.log(evt.latLng);

      // this.infoWindow.open(); // 打开信息窗
      // this.infoWindow.setPosition(evt.latLng); // 设置信息窗位置
      // this.infoWindow.setContent(evt.latLng.toString()); // 设置信息窗内容

      console.log(this.clickLocaltion.lat, this.clickLocaltion.lng);

    },
    
    // 获取当前设备地理位置
    getLocation() {
      this.$Location.getLocation(this.sucCallback, this.errCallback, { timeout: 5000 })
    },
    
    // 定位到地图初始化位置
    getInitCenterLocation() {
      const { lat, lng } = this.initCenter
      this.map.setCenter(new TMap.LatLng(lat, lng))
    },
    
    // 异步获取并显示地图中心点坐标
    async getCenterLoaction() {
      const centerLatLng = await this.map.getCenter()
      alert('当前坐标为' + JSON.stringify(centerLatLng))
    },
    
    // 定位成功回调
    sucCallback(res) {
      console.log(res, this.map, 'res')
      const { lat, lng } = res
      this.map.setCenter(new TMap.LatLng(lat, lng))
      this.map.setZoom('17.2')
    },
   // 当获取位置信息失败时的回调函数
    errCallback(err) {
      console.log(err, 'err') // 打印错误信息到控制台
    },
    // 控制提示信息显示与隐藏的方法
    hiddenTest() {
      this.resetDom() // 重置地图容器
      if (this.showTest) {
        this.hiddentestMap() // 如果当前显示提示，则调用隐藏文字地图模式
      } else {
        this.initMap() // 否则，重新初始化地图
      }
      this.showTest = !this.showTest // 切换显示/隐藏状态
    },
 // 初始化一个特殊地图模式，用于隐藏默认的文字标签
    hiddentestMap() {
      const { lat, lng } = this.initCenter // 获取初始中心点坐标
      const e = new this.$Map.LatLng(lat, lng) // 创建LatLng对象
      // 使用新的配置重新初始化地图，隐藏基础矢量图层的文字
      this.map = new TMap.Map(this.temp, {
        center: e, // 设置地图中心点坐标
        zoom: 17.2, // 设置地图缩放级别
        pitch: 43.5, // 设置俯仰角
        rotation: 45, // 设置地图旋转角度
        baseMap: { // 自定义底图样式
          type: 'vector', // 使用矢量图层
          features: ['base', 'building3d'] // 只显示基础图层和3D建筑，隐去了可能的文字标签
        }
      })
    },
      // 重置地图容器DOM的方法
    resetDom() {
      // 移除当前的地图容器元素
      this.temp.remove()
      
      // 获取地图外部容器元素
      const tencentMapBox = document.getElementById('tencentMapBox')
      
      // 重新创建一个新的div元素作为地图容器
      this.temp = document.createElement('div')
      
      // 为新创建的div元素设置id为'container'
      this.temp.id = 'container'
      
      // 将新创建的div元素插入到外部容器的最开始位置
      // 这样做可以在不改变外部结构的情况下更新地图容器
      tencentMapBox.insertBefore(this.temp, tencentMapBox.childNodes[0])
    }
  }
}
</script>

<style  lang="scss">
#info {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 14px;
  background: #fff;
  width: 270px;
  padding: 10px;
  border-radius: 3px;
}
#deviceInfo {
  position: absolute;
  left: 300px;
  top: 20px;
  font-size: 14px;
  background-color: rgba(135, 206, 250, 0.5);
  width: 600px;
  height: 50px;
  padding: 10px;
  border-radius: 3px;
}
#clickPopWindow {
  position: absolute;
  right: 70px;
  top: 50px;
  font-size: 14px;
  background: #fff;
  width: 270px;
  height: 350px;
  padding: 10px;
  border-radius: 3px;
}
</style>
